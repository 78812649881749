import { CLOSE_QUICKVIEW, RECEIVE_PRODUCTS, SHOW_QUICKVIEW, SAVE_BANNERS, SAVE_BRANDS, SAVE_CATEGORIES } from "../../constants/action-types"
import { findIndex } from "../../utils"

const initialState = {
  products: [],
  banners: [],
  productDetail: [],
  brands: [],
  categories: [],
  quickView: false
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        products: action.products
      };

    case SAVE_BANNERS:
      return {
        ...state,
        banners: action.images
      }

    case SAVE_BRANDS:
      return {
        ...state,
        brands: action.brands
      }

    case SAVE_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      }

    case SHOW_QUICKVIEW:
      let index = findIndex(state.products, product => product.id === action.productId);
      if (-1 !== index) {
        const item = state.products[index];
        return {
          ...state,
          quickView: true,
          productDetail: item
        };
      }
      break;

    case CLOSE_QUICKVIEW:
      return { ...state, quickView: false };

    default:
      return state;
  }
}

export default productReducer
