import { AuthenticationActions, AuthenticationState } from 'react-aad-msal'
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  initializing: false,
  initialized: false,
  idToken: null,
  accessToken: null,
  isPortalVerified: false,
  isLoggedIn: false,
  state: AuthenticationState.Unauthenticated === 'Authenticated',
  details: {
    Address: '',
    City: '',
    CustomerName: '',
    Email: '',
    PhoneNo: '',
    PostCode: ''
  }
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthenticationActions.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false,
      }
    case AuthenticationActions.Initialized:
      return {
        ...state,
        initializing: false,
        initialized: true,
      }
    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.payload,
      }
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload,
      }
    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        accessToken: null,
      }
    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        account: action.payload.account,
        isLoggedIn: true,
      }
    case AuthenticationActions.LoginError:
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
      return {
        ...state,
        idToken: null,
        accessToken: null,
        account: null,
        isPortalVerified: false,
        isLoggedIn: false
      }
    case AuthenticationActions.AuthenticatedStateChanged:
      return {
        ...state,
        state: action.payload === 'Authenticated',
      }
    case 'PORTAL_VERIFIED':
      return {
        ...state,
        isPortalVerified: action.isPortalVerified,
      }
    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details
      }
    default:
      return state
  }
}

const persistConfig = {
  keyPrefix: "molla-",
  key: "login",
  storage
}

export default persistReducer(persistConfig, userReducer);
