import * as types from '../../constants/action-types'
import { findIndex } from '../../utils';

const initialState = {
  value: { min: 0, max: 100000 },
  customValue: { min: 0, max: 100000 },
  sortBy: 'popularity',
  search: '',
  category: '',
  brand: '',
  rating: [],
  redirect: false,
}

function filterReducer(state = initialState, action) {
  let index;
  switch (action.type) {
    case types.SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy
      };

    case types.CATEGORY_FILTER:
      return {
        ...state,
        category: action.payload.category,
        redirect: action.payload.redirect
      };

    case types.RESET_FILTER:
      return {
        value: { min: 0, max: 100000 },
        customValue: { min: 0, max: 100000 },
        sortBy: 'popularity',
        search: '',
        category: '',
        brand: '',
        rating: [],
        redirect: false
      };

    case types.BRAND_FILTER:
      return {
        ...state,
        brand: action.payload.brand
      };

    case types.RESET_CATEGORY_FILTER:
      return {
        ...state,
        category: '',
        redirect: action.redirect
      }

    case types.RESET_REDIRECT:
      return {
        ...state,
        redirect: false
      }

    case types.RATING_FILTER:
      let rating = Object.assign([], state.rating);
      index = findIndex(rating, item => item === action.rating);
      if (-1 === index) {
        rating.push(action.rating);
      } else {
        rating.splice(index, 1);
      }
      return {
        ...state,
        rating: rating
      };

    case types.REFRESH_STORE:
      return { ...state, initialState };

    case types.PRICE_FILTER:
      return {
        ...state,
        value: action.range
      };

    case types.CUSTOM_PRICE_FILTER:
      return {
        ...state,
        customValue: action.range
      };

    case types.SEARCH_FILTER:
      return {
        ...state,
        search: action.payload.search
      }

    default:
      return state;
  }
}

export default filterReducer;
