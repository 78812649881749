import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  cardName: '',
  cardNumber: '',
  expiry: '',
  cvv: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  streetAddress: '',
  city: '',
  province: '',
  postalCode: '',
  contactNumber: '',
  country: '',
}

function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case 'CARD_NAME':
      return {
        ...state,
        cardName: action.payload.cardName,
      }
    case 'CARD_NUMBER':
      return {
        ...state,
        cardNumber: action.payload.cardNumber,
      }
    case 'EXPIRY':
      return {
        ...state,
        expiry: action.payload.expiry,
      }
    case 'CVV':
      return {
        ...state,
        cvv: action.payload.cvv,
      }
    case 'FIRST_NAME':
      return {
        ...state,
        firstName: action.payload.firstName,
      }
    case 'LAST_NAME':
      return {
        ...state,
        lastName: action.payload.lastName,
      }
    case 'EMAIL_ADDRESS':
      return {
        ...state,
        emailAddress: action.payload.emailAddress,
      }
    case 'STREET_ADDRESS':
      return {
        ...state,
        streetAddress: action.payload.streetAddress,
      }
    case 'CITY':
      return {
        ...state,
        city: action.payload.city,
      }
    case 'PROVINCE':
      return {
        ...state,
        province: action.payload.province,
      }
    case 'POSTAL_CODE':
      return {
        ...state,
        postalCode: action.payload.postalCode,
      }
    case 'CONTACT_NUMBER':
      return {
        ...state,
        contactNumber: action.payload.contactNumber,
      }
    case 'COUNTRY':
      return {
        ...state,
        country: action.payload.country,
      }
    default:
      return state
  }
}

const persistConfig = {
  keyPrefix: "molla-",
  key: "checkout",
  storage
}

export default persistReducer(persistConfig, checkoutReducer);
