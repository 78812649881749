import * as api from '../../api'
import * as types from '../../constants/action-types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

/********** Product Action ********/
export const SetHomePage = data => ({
  type: types.SET_HOMEPAGE,
  data
})

// receive products
export const receiveProducts = products => ({
  type: types.RECEIVE_PRODUCTS,
  products
});

// Store items
export const saveBanners = images => ({
  type: types.SAVE_BANNERS,
  images
});

// Store brands list
export const saveBrands = brands => ({
  type: types.SAVE_BRANDS,
  brands
});

// Store categories list
export const saveCategories = categories => ({
  type: types.SAVE_CATEGORIES,
  categories
});

// refresh local storage
export const refreshUnSafe = (current) => ({
  type: types.REFRESH_STORE,
  current
})

export const refreshStore = (current) => dispatch => {
  dispatch(refreshUnSafe(current));
}

// get Home page Data
export const getHomePageData = () => dispatch => {
  api.getHomePageData().then(data => {
    dispatch(SetHomePage(data))
    return data;
  })
}

// get all products
export const getAllProducts = () => dispatch => {
  api.getProducts().then(products => {
    dispatch(receiveProducts(products));
    return products;
  })
}

export const getBannerItems = () => dispatch => {
  api.getBannerImages().then(images => {
    dispatch(saveBanners(images));
    return images;
  })
}

export const getBrands = () => dispatch => {
  api.getBrands().then((result) => {
    dispatch(saveBrands(result));
    return result;
  })
}

export const getCategories = () => dispatch => {
  api.getCategories().then((result) => {
    dispatch(saveCategories(result));
    return result;
  })
}

export const applyCategoryFilter = (index) => dispatch => {
  if (index === 'All') {
    dispatch({
      type: 'RESET_CATEGORY_FILTER'
    })
  } else {
    dispatch({
      type: 'CATEGORY_FILTER',
      payload: {
        category: index,
      }
    })
  }
}

// Get custom products after login
export const getAllProductsLogin = (user) => dispatch => {
  api.getCustomProducts(user).then(products => {
    dispatch(receiveProducts(products));
    return products;
  })
}

/*********** Modal related Action **********/
// display quickview
export const showQuickViewModal = productId => ({
  type: types.SHOW_QUICKVIEW,
  productId
})

// close quickview modal
export const closeQuickViewModal = () => ({
  type: types.CLOSE_QUICKVIEW
})

// Show Video & Login modal
export const showModal = (modal) => ({
  type: types.SHOW_MODAL,
  modal: modal
});

// close Video & Login modal
export const closeModal = (modal) => ({
  type: types.CLOSE_MODAL,
  modal: modal
});

/************ Cart Action **************/
// remove item from wishlist
export const removeFromWishlist = productId => (dispatch) => {
  toast.error("Item removed from Wishlist");
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    productId
  })
};

// add item to cart from wishlist
export const addToCartFromWishlist = (product, qty) => (dispatch) => {
  toast.success("Item added to Cart");

  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    productId: product.id
  })

  dispatch(addToCartUnsafe(product, qty))
}

/*********** Wishlist Action *********/
// add item to wishlist
export const toggleWishlist = (product) => (dispatch) => {
  dispatch(toggleWishlistUnsafe(product))
}

// add item to wishlist : typical action
export const toggleWishlistUnsafe = (product) => ({
  type: types.TOGGLE_WISHLIST,
  product
});

// add item to cart
export const addToCart = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
}

// add item to cart : typical action
export const addToCartUnsafe = (product, qty) => ({
  type: types.ADD_TO_CART,
  product,
  qty
});

// remove item from cart
export const removeFromCart = productId => (dispatch) => {
  toast.error("Item removed from Cart");

  dispatch({
    type: types.REMOVE_FROM_CART,
    productId
  })
};

// Clear cart items after processing order
export const clearCart = item => (dispatch) => {
  dispatch({
    type: types.REFRESH_STORE,
  })
};

// change item's qty
export const changeQty = (productId, qty) => ({
  type: types.CHANGE_QTY,
  productId,
  qty
});

// change shipping method
export const changeShipping = (shipping) => ({
  type: types.CHANGE_SHIPPING,
  shipping
})

export const updateValue = event => (dispatch) => {
  dispatch({
    type: event.target.id,
    payload: {
      [event.target.name]: event.target.value,
    }
  })
}

/************** Filter Action ***********/
// set order to sort
export const filterSort = (sortBy) => (dispatch) => {
  dispatch({
    type: types.SORT_BY,
    sortBy
  })
}

// set price range to get suitable products
export const filterPrice = (range) => (dispatch) => {
  dispatch({
    type: types.PRICE_FILTER,
    range
  })
}

export const filterCustomPrice = (range) => (dispatch) => {
  dispatch({
    type: types.CUSTOM_PRICE_FILTER,
    range
  })
}

// add/remove category to get suitable products
export const toggleCategoryFilter = (category) => (dispatch) => {
  dispatch({
    type: types.CATEGORY_FILTER,
    category
  })
}

// add/remove brand to get suitable products
export const toggleBrandFilter = (brand) => (dispatch) => {
  dispatch({
    type: types.BRAND_FILTER,
    brand
  })
}

// add/remove rating to get suitable products
export const toggleRatingFilter = (rating) => (dispatch) => {
  dispatch({
    type: types.RATING_FILTER,
    rating
  })
}

//search value filter
export const searchFilter = (search) => (dispatch) => {
  dispatch({
    type: types.SEARCH_FILTER,
    search
  })
}

// reset filter with intialstate
export const resetFilter = () => (dispatch) => {
  dispatch({
    type: types.RESET_FILTER
  })
}
