// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const SAVE_BANNERS = 'SAVE_BANNERS'
export const SAVE_BRANDS = 'SAVE_BRANDS'
export const SAVE_CATEGORIES = 'SAVE_CATEGORIES'

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE'

// Home Page
export const SET_HOMEPAGE = "SET_HOMEPAGE"

//Quick
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW'
export const CLOSE_QUICKVIEW = 'CLOSE_QUICKVIEW'

// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CHANGE_QTY = 'CHANGE_QTY'
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING'

// WishList
export const TOGGLE_WISHLIST = 'TOGGLE_WISHLIST'
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST'

//Product Filters
export const SORT_BY = 'SORT_BY'
export const CATEGORY_FILTER = 'CATEGORY_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const BRAND_FILTER = 'BRAND_FILTER'
export const PRICE_FILTER = 'PRICE_FILTER'
export const CUSTOM_PRICE_FILTER = 'CUSTOM_PRICE_FILTER'
export const RATING_FILTER = 'RATING_FILTER'
export const SEARCH_FILTER = 'SEARCH_FILTER'
export const RESET_CATEGORY_FILTER = 'RESET_CATEGORY_FILTER'
export const RESET_REDIRECT = 'RESET_REDIRECT'

// Modal
export const SHOW_MODAL = 'SHOW_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
