import  { notification } from 'antd'
import apiClient from './axios'
import Swal from 'sweetalert2'

notification.config({
  duration: 5,
})

// API to get home page data
export const getHomePageData = async () => {
  return apiClient.get('/central/sitesetup')
    .then((response) => {
      return response.data.SiteData
    })
    .catch((error) => {
      console.log(error)
    })
}

// API to get products
export const getProducts = async () => {
  return apiClient.get('/central/getitems')
    .then((response) => {
      return response.data.items;
    })
    .catch((error) => {
      // handle error
      console.log(error)
    })
}

// API to get banner images
export const getBannerImages = async () => {
  return apiClient.get('/central/homeimages')
    .then((response) => {
      return response.data.images;
    })
    .catch((error) => {
      // handle error
      console.log(error)
    })
}

// API to get products with custom pricing
export const getCustomProducts = async (user) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-tokens': user.idToken.idToken.rawIdToken,
  }
  return apiClient.get('/central/getloginitems', { headers: headers })
    .then((response) => {
      return response.data.items;
    })
    .catch((error) => {
      // handle error
      console.log(error)
    })
}

export const getBrands = async () => {
  return apiClient.get('/central/brands')
    .then((response) => {
      return response.data.brands;
    })
    .catch((error) => {
      // handle error
      console.log(error)
    })
}

export const getCategories = async () => {
  return apiClient.get('/central/categories')
    .then((response) => {
      return response.data.categories;
    })
    .catch((error) => {
      // handle error
      console.log(error)
    })
}

export const getPreviousOrders = async (user) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-tokens': user.idToken.idToken.rawIdToken,
  }
  return apiClient
    .get('/getsalesorders', { headers: headers })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export const processOrder = async (cart, user, details) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-tokens': user.idToken.idToken.rawIdToken,
  }
  Swal.fire({
    title: 'Please Wait !',
    html: 'We are processing your order',
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => {
      Swal.showLoading()
    },
  }).then(() => { })
  return apiClient.post('/central/processcart',
    {
      cart: cart,
      userDetails: details
    },
    {
      headers: headers,
    }).then(response => {
      if (response) {
        return response.data
      }
      return { message: "An unknown error occurred" }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: `${err.data.error.message}`,
        showConfirmButton: false,
        timer: 4000,
      }).then(() => { })
    })
}

export const verifyLogin = async (user) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-tokens': user.idToken.idToken.rawIdToken,
  }
  Swal.fire({
    title: 'Please Wait !',
    html: 'We are verifying your credentials',
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => {
      Swal.showLoading()
    },
  }).then(() => { })
  return apiClient.get('/central/get-customer', { headers: headers })
    .then(response => {
      const { data: {customer: { EnablePortalAccess } } } = response;
      if (EnablePortalAccess) {
        notification.success({
          message: 'Log In',
          description: `Welcome ${response.data.customer.ContactName}!`,
        })
      }
      if (response) {
        return response.data
      }
      return { message: "An unknown error occurred" }
    }).catch(err => {
      console.log('error message here of admin')
      Swal.fire({
        icon: 'error',
        title: `${err.data.error.message}`,
        showConfirmButton: false,
        timer: 4000,
      }).then(() => { })
      throw err
    })
}

export const registerUser = async (user, logout) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-tokens': user.idToken.idToken.rawIdToken,
  }
  Swal.fire({
    title: 'Please Wait !',
    html: 'Processing registration...',
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => {
      Swal.showLoading()
    },
  }).then(() => { })
  return apiClient.post('/central/create-customer', {}, {
    headers: headers,
  }).then(response => {
    if (response) {
      Swal.fire({
        icon: 'success',
        title: 'Registration completed Successfully! You can login.',
        showConfirmButton: false,
        timer: 3500,
      }).then(() => {
        logout()
        return response.data
      })
    }
    return { message: "An unknown error occurred" }
  }).catch(err => {
    Swal.fire({
      icon: 'error',
      title: `${err.data.error.message}`,
      showConfirmButton: false,
      timer: 4000,
    }).then(() => {
      logout()
    })
  })
}
