import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoadingOverlay from '../components/features/loading-overlay'
import { scrollTop } from '../utils'
const ProductPages = React.lazy(() => import('./products-route.js'));
const ShopPages = React.lazy(() => import('./shop-route.js'));
const OtherPages = React.lazy(() => import('./others-route.js'));
const HomePage = React.lazy(() => import('./home-route.js'));

const AppRoot = () => {
  useEffect(() => {
    scrollTop();
  }, [])

  return (
    <React.Suspense fallback={<LoadingOverlay />}>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/product`} component={ProductPages} />
        <Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPages} />
        <Route path={`${process.env.PUBLIC_URL}/about`} component={OtherPages} />
        <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
      </Switch>
    </React.Suspense>
  )
}

export default AppRoot
