import { combineReducers } from 'redux'
import userReducer from './user'
import homeReducer from './home'
import checkoutReducer from './checkout'
import productReducer from './products'
import cartReducer from './cart'
import wishlistReducer from './wishlist'
import filterReducer from './filter'
import modalReducer from './modal'

const rootReducer = combineReducers({
  user: userReducer,
  home: homeReducer,
  checkoutData: checkoutReducer,
  data: productReducer,
  cartlist: cartReducer,
  wishlist: wishlistReducer,
  filters: filterReducer,
  modal: modalReducer
});

export default rootReducer;
