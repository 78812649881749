import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.BACKEND_URL,
})

apiClient.interceptors.request.use(request => {
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error

  throw response
})

export default apiClient
