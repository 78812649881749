import * as types from '../../constants/action-types'

const initialState = {
  "Logo": "",
  "BrandSpotlight": [],
  "AboutUs": "",
  "Social": [],
  "WeAccept": [],
  "LocationTitle": "",
  "Location": [],
  "FooterMenuTitle": [],
  "FooterMenu": []
}

function homeReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_HOMEPAGE:
      return {
        ...state,
        Logo: action.data.Logo,
        BrandSpotlight: action.data.BrandSpotlight,
        AboutUs: action.data.AboutUs,
        Social: action.data.Social,
        WeAccept: action.data.WeAccept,
        LocationTitle: action.data.LocationTitle,
        Location: action.data.Location,
        FooterMenuTitle: action.data.FooterMenuTitle,
        FooterMenu: action.data.FooterMenu
      }

    default:
      return state
  }
}

export default homeReducer
